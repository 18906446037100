import React, { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CircularProgress, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CONTENT_SPACING_LARGE } from "../../theme/theme";
import { PageHeading } from "../../components/typography/page-heading";
import { PageActions } from "../../components/containers/page-actions";
import { PrimaryActionButton } from "../../components/button/primary-action-button";
import { AppContext } from "../../app/context/app-context";
import {
  useCertificatePdfLazyQuery,
  useSendCertificateToUserMutation,
} from "../../api/neuroth-earwear/graphql/generated";
import { HearingProtectionTestContext } from "../hearing-protection-test/context/hearing-protection-test-context";
import { ROUTES } from "../../router/router";
import { ConfirmationDialog } from "../../components/confirmation-dialog/confirmation-dialog";
import { OverviewGraphic } from "../overview/overview-graphic";
import { CertificateSentDialog } from "./certificate-sent-dialog";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    marginTop: CONTENT_SPACING_LARGE,
  },
  graphicPlaceholder: {
    height: 120,
    width: "100%",
    backgroundColor: theme.palette.grey["200"],
  },
  graphicPlaceholderText: {
    color: "white",
  },

  emailErrorActive: {
    opacity: 1,
  },
  serialHint: {
    opacity: 0,
    transition: "opacity 0.2s linear;",
    color: theme.palette.primary.main,
    fontSize: 13,
  },
  serialHintActive: {
    opacity: 0.6,
  },
  diagram: {
    marginTop: CONTENT_SPACING_LARGE * 1.5 + theme.spacing(6),
    marginBottom: CONTENT_SPACING_LARGE,
  },
  spacingTop: {
    marginTop: theme.spacing(6),
  },
}));

interface ISummaryProps {}

const downloadBase64 = (contentType: string, base64Data: string, fileName: string) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const SummaryPage: FunctionComponent<ISummaryProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [isCertificateSendDialogOpen, setIsCertificateSendDialogOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { testId, normalizedTestResult, speakerType, testCreatedAt } = useContext(HearingProtectionTestContext);
  const { name, serialNumber } = useContext(AppContext);

  const lastName = useMemo(() => {
    if (name) {
      const words = name.split(" ");
      if (words.length) {
        return words[words.length - 1];
      }
    }
    return "";
  }, [name]);

  const testCreatedAtFormatted = useMemo(() => {
    if (testCreatedAt && testCreatedAt.toFormat) {
      return testCreatedAt.toFormat("yyyyLLdd");
    }
    return "";
  }, [testCreatedAt]);

  const [certificateQuery, { loading: downloadLoading }] = useCertificatePdfLazyQuery({
    onCompleted: (data) => {
      downloadBase64(
        "application/pdf",
        data.certificatePdf.base64,
        `Prüfprotokoll_${lastName}_${serialNumber}_${testCreatedAtFormatted}.pdf`,
      );
      setIsDialogOpen(false);
      history.push({
        pathname: ROUTES.EARWEAR_ACCOUNT.PATH,
      });
    },
  });

  const [sendCertificateQuery, { loading: sendLoading }] = useSendCertificateToUserMutation({
    onCompleted: (data) => {
      setIsCertificateSendDialogOpen(true);
    },
    onError: console.error,
  });

  const { setProgress, productVariant, productFilter } = useContext(AppContext);

  const fullProductName = useMemo(() => `${productVariant?.name} ${productFilter?.name}`, [
    productVariant,
    productFilter,
  ]);

  const isEmailValid = useMemo(() => {
    return (
      !!email.match(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
      ) || email.length === 0
    );
  }, [email]);

  const emailLabelClassName = useMemo(() => {
    let hintEnabled = !!(!isEmailValid && email);
    return `${classes.serialHint} ${hintEnabled ? classes.serialHintActive : ""}`;
  }, [classes.serialHint, classes.serialHintActive, isEmailValid, email]);

  const onConfirm = useCallback(() => {
    if (email.length === 0) {
      setIsDialogOpen(true);
      return;
    }

    if (testId) {
      sendCertificateQuery({
        fetchPolicy: "no-cache",
        variables: {
          info: {
            receiver: email,
            testId,
          },
          testId,
        },
      });
    }
  }, [email, sendCertificateQuery, testId]);

  const onDownloadPdf = useCallback(() => {
    certificateQuery({ variables: { testId: testId! } });

    // history.push({
    //   pathname: ROUTES.EARWEAR_ACCOUNT.PATH,
    // });
  }, [certificateQuery, testId]);

  useEffect(() => {
    setProgress(100);
  });

  return (
    <Fragment>
      <Grid item xs container direction="column" justify="space-between">
        <Grid item container alignItems="center">
          <Grid container item xs={12} alignItems="center">
            <PageHeading>{t("summary.heading")}</PageHeading>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} className={classes.spacingTop}>
              <Typography variant="body1">{t("summary.body_1")}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.diagram}>
              {speakerType && (
                <OverviewGraphic speakerType={speakerType} normalizedTestResult={normalizedTestResult!} />
              )}
            </Grid>

            <Grid item xs={12} className={classes.spacingTop}>
              <Typography variant="body1">{t("summary.body_2", { fullProductName })}</Typography>
            </Grid>

            <Grid item xs={12} className={classes.spacingTop}>
              <TextField
                variant="outlined"
                type="email"
                fullWidth
                label={`${t("earwear_account.dialog.fields.email")}*`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!isEmailValid}
              />
              <Typography variant="body2" className={emailLabelClassName}>
                {t("summary.email_fail")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <PageActions
            actions={[
              <PrimaryActionButton onClick={onConfirm} disabled={!isEmailValid || sendLoading}>
                {sendLoading ? <CircularProgress size="23px" color="secondary" /> : t("summary.button_1")}
              </PrimaryActionButton>,
            ]}
          />
        </Grid>
      </Grid>
      <CertificateSentDialog open={isCertificateSendDialogOpen} onClose={() => setIsCertificateSendDialogOpen(false)} />
      <ConfirmationDialog
        open={isDialogOpen}
        heading={t("summary.dialog.heading")}
        content={t("summary.dialog.content")}
        leftActionText={t("summary.dialog.left_button")}
        rightActionText={
          downloadLoading ? <CircularProgress size="23px" color="secondary" /> : t("summary.dialog.right_button")
        }
        leftAction={() => {
          setIsDialogOpen(false);
        }}
        rightAction={onDownloadPdf}
        rightActionDisabled={downloadLoading}
      />
    </Fragment>
  );
};
