import { FunctionComponent, useCallback, useContext, useEffect } from "react";
import { Theme, makeStyles, Typography, Grid, Button, Box } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { generatePath, useHistory } from "react-router";
import { use100vh } from "react-div-100vh";
import { ROUTES } from "../../router/router";
import { HearingProtectionTestContext } from "../hearing-protection-test/context/hearing-protection-test-context";
import { PageHeading } from "../../components/typography/page-heading";
import { PAGE_BOTTOM_PADDING, PAGE_MAX_WIDTH } from "../../theme/theme";
import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = runtimeEnv();

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "absolute",
    minWidth: "100vw",
    maxWidth: "100vw",
  },
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    flex: 1,
  },
  content: {
    width: "100%",
    maxWidth: PAGE_MAX_WIDTH,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    flex: 1,
  },
  heading: {
    letterSpacing: 16,
    fontSize: 76,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 56,
      letterSpacing: 12,
    },
    fontWeight: 700,
    color: "#FFFFFF",
  },
  earwearLogoText: {
    fontSize: 11,
    color: "#FFFFFF",
  },
  earwearLogo: {
    maxWidth: 175,
  },
  nextButtonContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: PAGE_BOTTOM_PADDING,
    width: "100%",
  },
  nextButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    width: "100%",
    marginBottom: theme.spacing(3),
  },
  spacingBottom: {
    marginBottom: theme.spacing(5),
  },
  fontColor: {
    color: "#FFFFFF",
  },
  message: {
    paddingTop: theme.spacing(20),
  },
  versionNumberContainer: {},
}));

interface ILandingPageProps {}

export const LandingPage: FunctionComponent<ILandingPageProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const screenHeight = use100vh();

  const onContinue = useCallback(() => {
    history.push(generatePath(ROUTES.DATA_INPUT.PATH));
  }, [history]);

  const { cancelTest } = useContext(HearingProtectionTestContext);

  useEffect(() => {
    cancelTest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper} style={{ height: screenHeight || undefined }}>
      <Grid container direction="column" alignItems="center" className={classes.container}>
        <Grid item container className={classes.content}>
          <Grid item container>
            <Grid item container direction="column" justify="center">
              <Grid item>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography className={classes.heading}>EarSave</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box marginBottom={3} textAlign="center">
                      <Typography className={classes.earwearLogoText}>{t("general.powered_by")}</Typography>
                    </Box>
                    <Grid container item xs={12} justify={"center"}>
                      <img className={classes.earwearLogo} src="/landing/earwear-logo.svg" alt="earwear-logo"></img>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} justify="center" className={classes.message}>
                    <Grid item container spacing={6}>
                      <Grid item>
                        <PageHeading className={classes.fontColor}>{t("general.welcome_message")}</PageHeading>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" className={classes.fontColor}>
                          {t("general.explanation_message_1")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" className={classes.fontColor}>
                          {t("general.explanation_message_2")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" className={classes.fontColor}>
                          {t("general.explanation_message_3")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container alignItems="flex-end" className={classes.nextButtonContainer}>
              <Grid container item xs>
                <Grid item xs={12}>
                  <Button variant="contained" onClick={onContinue} fullWidth className={classes.nextButton}>
                    {t("general.next")}
                  </Button>
                </Grid>
                <Grid item xs={12} container justify="center">
                  <Typography align="center" variant="caption">
                    {t("general.version", { version: env.REACT_APP_VERSION_NUMBER })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
