import React, { FunctionComponent, useCallback, useContext, useEffect } from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../components/typography/page-heading";
import { PageActions } from "../../components/containers/page-actions";
import { PrimaryActionButton } from "../../components/button/primary-action-button";
import { ROUTES } from "../../router/router";
import { generatePath, useHistory } from "react-router";
import { HearingProtectionTestContext } from "../hearing-protection-test/context/hearing-protection-test-context";
import { AppContext } from "../../app/context/app-context";
import { OverviewGraphic } from "./overview-graphic";
import { CONTENT_SPACING_LARGE } from "../../theme/theme";

const useStyles = makeStyles((theme: Theme) => ({
  spacingTop: {
    marginTop: theme.spacing(6),
  },
  content: {
    marginTop: CONTENT_SPACING_LARGE * 2,
  },
}));

interface IOverviewProps {}

export const OverviewPage: FunctionComponent<IOverviewProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { speakerType, normalizedTestResult } = useContext(HearingProtectionTestContext);
  const { isDataInputSkipped } = useContext(AppContext);

  const onContinue = useCallback(() => {
    if (!isDataInputSkipped) {
      history.push(generatePath(ROUTES.SECURITY_INFORMATION.PATH));
    } else {
      history.push(generatePath(ROUTES.LANDING.PATH));
    }
  }, [history, isDataInputSkipped]);
  const { setProgress } = useContext(AppContext);

  useEffect(() => {
    setProgress(90);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs container direction="column" justify="space-between">
      <Grid container item alignItems="center">
        <Grid container item xs={12} alignItems="center">
          <PageHeading>{t("overview.heading")}</PageHeading>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{t("overview.body_1")}</Typography>
          {!isDataInputSkipped && (
            <Typography variant="body1" className={classes.spacingTop}>
              {t("overview.body_2")}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} className={classes.content}>
          {speakerType && <OverviewGraphic speakerType={speakerType} normalizedTestResult={normalizedTestResult!} />}
        </Grid>
      </Grid>
      <Grid item>
        <PageActions
          actions={[
            <PrimaryActionButton onClick={onContinue}>
              {!isDataInputSkipped ? t("general.next") : t("general.confirm_and_finish")}
            </PrimaryActionButton>,
          ]}
        />
      </Grid>
    </Grid>
  );
};
